.recipe-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .recipe-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;
    width: 300px;
  }
  
  .recipe-thumbnail {
    border-radius: 4px;
    height: 150px;
    object-fit: cover;
    width: 100%;
  }
  
  .recipe-summary {
    margin-top: 8px;
  }
  
  .recipe-ingredients {
    margin-top: 16px;
  }
  